/* -- NAVBAR -------------------------------------------------------------------------------------------------------------------------------------------------- */
@media (max-width: 767px) {
  #toggle-menu {
    padding-top: 2.0833333333em;
    padding-left: 2em;
    font-size: 0.75em;
    line-height: 1.25em;
  }
  #logo img {
    margin-top: 1.8333333333em;
    font-size: 0.75em;
  }
}

/* -- CONTENT ------------------------------------------------------------------------------------------------------------------------------------------------- */
@media (max-width: 991px) {
  #clients .rotator-container {
    height: 14em;
  }
  #clients .brand {
    height: 4em;
    margin-bottom: 1em;
  }
}

@media (max-width: 767px) {
  .content {
    height: auto;
  }
  #clients .content {
    padding-bottom: 3em;
  }
  #clients .rotator-container {
    height: 13.5em;
  }
  #clients .rotator-container .container {
    padding: 0 3em;
  }
  #clients .brand {
    height: 3em;
    margin-bottom: 0.5em;
  }
}

/* -- PROJECT SLIDE IN ---------------------------------------------------------------------------------------------------------------------------------------- */
@media (min-width: 768px) and (max-width: 991px) {
  .project.slide-in:nth-child(even) .inner {
    animation-delay: 100ms;
  }
}

@media (min-width: 992px) {
  .project.slide-in:nth-child(3n+2) .inner {
    animation-delay: 100ms;
  }
  .project.slide-in:nth-child(3n) .inner {
    animation-delay: 200ms;
  }
}
